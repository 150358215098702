import React from 'react'
import { motion } from 'framer-motion'
import Flex from './Flex'
import Text from './Text'

const Nav = () => (
  <Flex as='nav'>
    <Text
      as={motion.a}
      href='https://pinterest.com/zenworkspaces'
      fontFamily='heading'
      whileHover={{ opacity: 0.6 }}
    >
      Pinterest
    </Text>
  </Flex>
)

export default Nav
